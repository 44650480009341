const initYoutube = init => {
  if (window.YT && window.YT.Player) {
    init();
  } else {
    const script = document.createElement("script");
    script.async = true;
    script.onload = () => {
      setTimeout(init, 1000, false);
    };
    // script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "https://www.youtube.com/iframe_api");
    document.getElementsByTagName("head")[0].appendChild(script);
  }
};

export default initYoutube;
