import React, { Component, Fragment } from "react";
import { Button, GridColumn, Toastr } from "arv-reactcomponents";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { reaction } from "mobx";
import Analytics, { GTM } from "../../../../../analytics";
import Event from "../../../../../analytics/eventFactory";
import { FAVNSHARE } from "npmlinks-constants";

@inject("BrandStore", "LoginStore")
@observer
class DocFav extends Component {
  static toastrMessages(message, className, time) {
    Toastr.showToastr({
      className: `nwc-toastr-list-${className || "notification"} nw-toastr`,
      message,
      timeout: time || 3000,
    });
  }

  static triggerGTM(pdpDetails) {
    GTM.pushWithEvent(
      pdpDetails,
      Event.GTMEvent.PRODUCT_WISHLIST,
    );
  }

  constructor() {
    super();
    this.state = {
      isUserLoggedIn: false,
      faved: null,
      loading: false,
      addingPending: false,
    };
    this.init = this.init.bind(this);
    this.reactToLogin = this.reactToLogin.bind(this);
    this.toggleFav = this.toggleFav.bind(this);
    this.addToFav = this.addToFav.bind(this);
    this.checkIfBlogAdded = this.checkIfBlogAdded.bind(this);
    this.removeFromFav = this.removeFromFav.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
  }
  componentDidMount() {
    this.init();
    this.reactToLogin();
  }
  get favorited() {
    return this.state.isUserLoggedIn && this.state.faved ? "active" : "";
  }
  reactToLogin() {
    reaction(
      () => this.props.LoginStore.isUserLoggedIn,
      isUserLoggedIn => {
        const { isUserLoggedIn: stateLoggedIn } = this.state;
        if (isUserLoggedIn !== stateLoggedIn) {
          this.setState({ isUserLoggedIn });
          if (isUserLoggedIn) {
            this.init();
          }
        }
      },
    );
  }
  checkIfBlogAdded(response) {
    const { id } = this.props;
    const faved =
      (response &&
        response.find(
          item =>
            item.brandId === id || item.blogId === id || item.styleId === id,
        )) ||
      null;
    this.setState({ faved });
  }
  init() {
    const { isUserLoggedIn } = this.props.LoginStore;
    if (isUserLoggedIn) {
      this.toggleLoading();
      this.props.BrandStore.getAllFav().then(
        response => {
          this.toggleLoading();
          this.checkIfBlogAdded(response);
          if (this.state.addingPending) {
            this.setState({ addingPending: false });
            this.toggleFav(true);
          }
        },
        () => {
          DocFav.toastrMessages(FAVNSHARE.FAV.ERROR, "success");
          this.toggleLoading();
        },
      );
    }
    this.setState({ isUserLoggedIn });
  }
  toggleFav(postLogin) {
    const { id } = this.props;
    if (!this.state.isUserLoggedIn) {
      this.setState({ addingPending: true });
      this.props.LoginStore.setloginModal(true);
      Analytics.trackEvent({
        action: Event.action.SHORTLIST_PRE_LOGIN,
        label: id,
        category: this.props.category || window.pageType,
      });
      return null;
    }
    if (!this.favorited || postLogin) {
      this.addToFav();
    } else if (!postLogin) {
      this.removeFromFav();
    }
    return null;
  }
  addToFav() {
    const { id, type, pdpDetails } = this.props;
    let body;
    switch (type) {
      case "blogs":
        body = [{ blogId: id }];
        break;
      case "brands":
        body = [{ brandId: id }];
        break;
      default:
        body = [{ styleId: id }];
    }
    this.toggleLoading();
    this.props.BrandStore.addtoFav(JSON.stringify(body), type).then(
      response => {
        DocFav.toastrMessages(FAVNSHARE.FAV.SUCCESSFULL_ADD, "success");
        this.toggleLoading();
        this.checkIfBlogAdded(response);
        Analytics.trackEvent({
          action: Event.action.SHORLIST_SUCCESS,
          label: this.props.id,
          category: this.props.category || window.pageType,
        });
        DocFav.triggerGTM(pdpDetails);
      },
      () => {
        DocFav.toastrMessages(FAVNSHARE.FAV.ERROR, "success");
        this.toggleLoading();
      },
    );
  }
  removeFromFav() {
    this.toggleLoading();
    let body;
    switch (this.props.type) {
      case "blogs":
        body = {
          favouriteId: this.state.faved.id,
          favtype: "blogs",
        };

        break;
      case "brands":
        body = {
          favouriteId: this.state.faved.id,
          favtype: "brands",
        };

        break;
      default:
        body = {
          favouriteId: this.state.faved.id,
          favtype: "products",
        };
    }
    this.props.BrandStore.removeFav(JSON.stringify(body)).then(
      response => {
        DocFav.toastrMessages(FAVNSHARE.FAV.SUCCESSFULL_REMOVED, "success");
        this.toggleLoading();
        this.checkIfBlogAdded(response);
        Analytics.trackEvent({
          action: Event.action.SHORLIST_REMOVED,
          label: this.props.id,
          category: this.props.category || window.pageType,
        });
      },
      () => {
        DocFav.toastrMessages(FAVNSHARE.FAV.ERROR, "success");
        this.toggleLoading();
      },
    );
  }
  toggleLoading() {
    this.setState({ loading: !this.state.loading });
  }
  render() {
    const { view, className, showtext, pdpDetails } = this.props;
    return (
      <Fragment>
        <Button
          className={`nw-favnshare-docfavbutton is-${view} ${className} ${
            this.favorited
          }`}
          onClick={() => this.toggleFav()}
        >
          <i
            className={`nw-favnshare-docfavicon is-${view} icomoon-heart${
              this.favorited ? "" : "-o"
            } ${className}`}
          />
        </Button>
        {showtext && (
          <span className="nw-pdpdetails-iconstext">
            {` ${this.favorited ? "Favorited" : "Favorite"}`}
          </span>
        )}
      </Fragment>
    );
  }
}

DocFav.defaultProps = {
  view: "relative",
  id: "",
  className: "",
  type: "blogs",
  pdpDetails: [],
};

DocFav.propTypes = {
  className: PropTypes.string,
  view: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  pdpDetails: PropTypes.shape({}),
  LoginStore: PropTypes.shape({
    isUserLoggedIn: PropTypes.bool,
    setloginModal: PropTypes.func.isRequired,
  }).isRequired,
  BrandStore: PropTypes.shape({
    getAllFav: PropTypes.func,
    addtoFav: PropTypes.func,
    removeFav: PropTypes.func,
  }).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: DocFav,
  component: DocFav,
};

export default ProjectComponent;
