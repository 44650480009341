/* eslint-disable no-param-reassign */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Button, Modal, Toastr, Utils } from "arv-reactcomponents";
import { fbInit } from "../../../../../utils";
import  Analytics, { GTM } from "../../../../../analytics";
import Event from "../../../../../analytics/eventFactory";
import { FAVNSHARE } from "npmlinks-constants";
import Anchor from "../../../../Anchor";

@inject("ProductStore", "BrandStore", "CommonStore")
@observer
class DocShare extends Component {
  constructor(props) {
    super();
    this.state = {
      tabActive: false,
      shareModalOpen: false,
      isFbDisabled: true,
      copyDisabled: false,
      shortenedUrl: (props && props.url) || "",
      xpos: 0,
      ypos: 0,
    };
    this.toggleShare = this.toggleShare.bind(this);
    this.setFb = this.setFb.bind(this);
    this.fbShare = this.fbShare.bind(this);
    this.linkShareTwitter = this.linkShareTwitter.bind(this);
    this.linkShareWhatsapp = this.linkShareWhatsapp.bind(this);
    this.copyLink = this.copyLink.bind(this);
    this.copyLinkIOS = this.copyLinkIOS.bind(this);
    this.shareGAEvent = this.shareGAEvent.bind(this);
    this.shareHover = this.shareHover.bind(this);
    this.timeoutInterVal = null;
    this.closeHover = this.closeHover.bind(this);
  }
  componentDidMount() {
    this.timeoutInterVal = setInterval(() => {
      if (!this.state.isFbDisabled && this.timeoutInterVal) {
        clearInterval(this.timeoutInterVal);
      } else {
        fbInit(this.setFb);
      }
    }, 2000);
    window.addEventListener("scroll", this.closeHover);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.closeHover);
  }

  get twitterShare() {
    return `${FAVNSHARE.SHARE.TWITTER_EXTENSION}${
      this.state.shortenedUrl
    }&text=${this.props.title}`;
  }

  get whatsappShare() {
    const { isDesktop } = this.props.CommonStore;
    const whatsappUrl = isDesktop
      ? FAVNSHARE.SHARE.WHATSAPP_EXTENSION_DESKTOP
      : FAVNSHARE.SHARE.WHATSAPP_EXTENSION_MOBILE;
    return `${whatsappUrl}${this.state.shortenedUrl}`;
  }
  get category() {
    return this.props.BrandStore.isBT || this.props.category || window.pageType;
  }

  closeHover() {
    this.setState({ tabActive: false });
  }

  setFb() {
    this.setState({ isFbDisabled: false });
  }

  shareHover(bool, e) {
    const elem = e.target;
    const viewportOffset = elem.getBoundingClientRect();
    const { top, left, width } = viewportOffset;
    const { isDesktop } = this.props.CommonStore;

    if (isDesktop && bool) {
      Analytics.trackEvent({
        action: Event.action.SHARE_HOVER,
        category: this.category,
      });
    }
    if (isDesktop) {
      this.setState({ tabActive: bool, xpos: top, ypos: `${left + width}px` });
    }
  }
  shareGAEvent(channel) {
    const { GAObj } = this.props;
    const action =
      (GAObj &&
        Event.action.getMasterTemplateActions([
          GAObj.sectionIndex,
          GAObj.type,
        ])) ||
      (this.props.BrandStore.isBT && Event.action.BT_SHARE) ||
      Event.action.SHARE_SELECTED;
    const label =
      (GAObj &&
        Event.label.getMasterTemplateLabels([
          GAObj.componentIndex,
          channel,
          GAObj.identity,
        ])) ||
      (this.props.BrandStore.isBT && Event.label.getShareTypeBT(channel)) ||
      Event.label.getShareType(channel);
    Analytics.trackEvent({
      action,
      label,
      category: this.category,
    });
    GTM.pushWithEvent( {
      "channel": channel,
    },
    Event.GTMEvent.PRODUCT_SHARE,);
  }
  toggleShare() {
    const { isDesktop } = this.props.CommonStore;

    if (!isDesktop) {
      const flag = this.state.shareModalOpen;
      this.setState({ shareModalOpen: !flag });
      const shareModal = !flag
        ? Event.action.SHARE_CLICK
        : Event.action.SHARE_CLOSE;
      Analytics.trackEvent({
        action: shareModal,
        category: this.category,
      });
    }
  }
  fbShare() {
    const { image } = this.props;
    if (window.FB) {
      window.FB.ui({
        method: "share",
        href: this.state.shortenedUrl,
        title: this.props.title,
        picture: image,
        caption: FAVNSHARE.SHARE.FB_SHARE_TITLE,
        description: this.props.description,
      });
      this.shareGAEvent("fb");
    } else {
      console.log("fb not supported");
    }
  }
  // eslint-disable-next-line class-methods-use-this
  copyLinkIOS(el) {
    // eslint-disable-next-line one-var
    const oldContentEditable = el.contentEditable,
      oldReadOnly = el.readOnly,
      range = document.createRange();
    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);
    const s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);
    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;
  }
  copyLink() {
    try {
      const inp = document.createElement("input");
      document.body.appendChild(inp);
      inp.value = this.props.url;
      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        this.copyLinkIOS(inp);
      } else {
        inp.select();
      }
      document.execCommand("copy", false);
      inp.remove();
      Toastr.showToastr({
        className: "nwc-toastr-list-notification nw-toastr",
        message: "Link Copied",
        timeout: 3000,
      });
      this.toggleShare();
    } catch (err) {
      this.setState({ copyDisabled: true });
    }
  }

  linkShareTwitter() {
    this.shareGAEvent("twitter");
  }

  linkShareWhatsapp() {
    this.shareGAEvent("whatsapp");
  }

  render() {
    const { view, className, category } = this.props;
    const boxShadow = category !== "pdp" ? "has-shadow" : "";
    const { tabActive, xpos, ypos } = this.state;
    return (
      <Fragment>
        <div
          className={`nw-docshare-container ${className}`}
          onMouseEnter={e => this.shareHover(true, e)}
          onMouseLeave={e => this.shareHover(false, e)}
        >
          <Button
            className={`nw-docshare-button is-${view} ${className}`}
            onClick={this.toggleShare}
          >
            <i
              className={`icomoon-share nw-docshare-buttonicon  is-${view} ${className}`}
            />
          </Button>
          <div
            style={{ top: xpos, left: ypos }}
            className={`nw-docshare-list  ${boxShadow} is-${view} ${className} ${(tabActive &&
              "is-active") ||
              ""}`}
          >
            <Button
              className={`nw-docshare-listitem nw-favnshare-fbshare is-${view} ${
                this.state.isFbDisabled ? "nwc-hide" : ""
              } ${className}`}
              onClick={this.fbShare}
            >
              <i className={`icomoon-facebook ${className}`} />
            </Button>
            <Anchor.component
              to={this.twitterShare}
              href={this.twitterShare}
              target="_blank"
              className={`is-${view} nw-docshare-listitem nw-favnshare-twittershare ${className}`}
              onClick={this.linkShareTwitter}
            >
              <i className={`icomoon-twitter ${className}`} />
            </Anchor.component>
            <Anchor.component
              to={this.whatsappShare}
              href={this.whatsappShare}
              target="_blank"
              data-original-title="whatsapp"
              rel="tooltip"
              data-placement="left"
              data-action="share/whatsapp/share"
              className={`is-${view} nw-docshare-listitem nw-favnshare-whatsappshare`}
              onClick={this.linkShareWhatsapp}
            >
              <i className="icomoon-whatsapp" />
            </Anchor.component>
          </div>
        </div>
        <Modal
          className={`nw-favnshare-sharemobile ${className}`}
          isOpen={this.state.shareModalOpen}
          onClose={this.toggleShare}
        >
          <div className={`nw-favnshare-sharemobile-enclose ${className}`}>
            <div
              role="button"
              className={`nwc-btn nw-favnshare-sharemobileitem ${
                this.state.isFbDisabled ? "disabled" : ""
              } ${className}`}
              onClick={this.fbShare}
              tabIndex="0"
              onKeyPress={Utils.noop}
            >
              <i
                className={`icomoon-facebook fb nw-favnshare-sharemobilelogo ${className}`}
              />
              <span className={`nw-favnshare-fbsharemobiletext ${className}`}>
                FACEBOOK
              </span>
            </div>
            <Anchor.component
              to={this.twitterShare}
              href={this.twitterShare}
              target="_blank"
              className={`nw-favnshare-sharemobileitem ${className}`}
              onClick={this.linkShareTwitter}
            >
              <i
                className={`icomoon-twitter twitter  nw-favnshare-sharemobilelogo ${className}`}
              />
              <span className={`nw-favnshare-fbsharemobiletext ${className}`}>
                TWITTER
              </span>
            </Anchor.component>
            <Anchor.component
              to={this.whatsappShare}
              href={this.whatsappShare}
              target="_blank"
              data-original-title="whatsapp"
              rel="tooltip"
              data-placement="left"
              data-action="share/whatsapp/share"
              className="nw-favnshare-sharemobileitem"
              onClick={this.linkShareWhatsapp}
            >
              <i className="icomoon-whatsapp whatsapp nw-favnshare-sharemobilelogo" />
              <span className="nw-favnshare-fbsharemobiletext">WHATSAPP</span>
            </Anchor.component>
            <div
              role="button"
              className={`nwc-btn nw-favnshare-sharemobileitem ${
                this.state.copyDisabled ? "disabled" : ""
              } ${className}`}
              onClick={this.copyLink}
              disabled={this.state.copyDisabled}
              tabIndex="0"
              onKeyPress={Utils.noop}
            >
              <i
                className={`icomoon-copy copy  nw-favnshare-sharemobilelogo ${className}`}
              />
              <span className={`nw-favnshare-fbsharemobiletext ${className}`}>
                COPY LINK
              </span>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

DocShare.wrappedComponent.defaultProps = {
  title: "",
  image: "",
  view: "relative",
  url: "",
  description: "",
  className: "",
  category: "",
};

DocShare.wrappedComponent.propTypes = {
  category: PropTypes.string,
  className: PropTypes.string,
  view: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  GAObj: PropTypes.shape({}).isRequired,
  BrandStore: PropTypes.shape({
    isBT: PropTypes.string.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("ProductStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: DocShare.wrappedComponent,
  component: DocShare,
};

export default ProjectComponent;
