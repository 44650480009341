import React, { Component } from "react";
import PropTypes from "prop-types";
import { GridRow, GridColumn } from "arv-reactcomponents";
import DocShare from "./DocShare";
import DocFav from "./DocFav";


class FavAndShare extends Component {
  get columnLayout() {
    const { view } = this.props;
    const className = "nw-favnshare-column";
    const viewClass =
      (view === "absolute" && "nwc-grid-col-sm-12") || "nwc-grid-col-sm-6";
    return `${className} ${viewClass} is-${view}`;
  }
  render() {
    const {
      view,
      shareable,
      favoritable,
      showtext,
      show,
      pdpDetails,
      ...otherProps
    } = this.props;
    const toShow = show || favoritable || shareable;
    if (!toShow || !this.props.id) {
      return null;
    }
    return (
      <GridRow
        className={`nw-favnshare-container is-${view} ${otherProps.className ||
          ""}`}
      >
        {(favoritable && (
          <GridColumn
            className={`${this.columnLayout} ${otherProps.className || ""}`}
          >
            <DocFav.component view={view} pdpDetails={pdpDetails} showtext={showtext} {...otherProps} />
          </GridColumn>
        )) ||
          null}
        {(shareable && (
          <GridColumn
            className={`${this.columnLayout} ${otherProps.className || ""}`}
          >
            <DocShare.component view={view} {...otherProps} />
            {showtext && <span className="nw-pdpdetails-iconstext">SHARE</span>}
          </GridColumn>
        )) ||
          null}
      </GridRow>
    );
  }
}

FavAndShare.defaultProps = {
  view: "relative",
  shareable: false,
  favoritable: false,
  show: false,
  pdpDetails: [],
};

FavAndShare.propTypes = {
  view: PropTypes.string,
  shareable: PropTypes.bool,
  favoritable: PropTypes.bool,
  show: PropTypes.bool,
  pdpDetails: PropTypes.shape({}),
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: FavAndShare,
  component: FavAndShare,
};

export { default as DocShare } from "./DocShare";
export { default as DocFav } from "./DocFav";
export { default as DocSharev2 } from "./DocSharev2";
export default ProjectComponent;
