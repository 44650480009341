import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Loader } from "arv-reactcomponents";
import { MASTER_TEMPLATE_COMPONENT_CONFIGS } from "npmlinks-constants";
import {
  getBackgroundColorCode,
  queryParamsToObject,
  deduceValuesFromPipedData,
} from "../../../../utils";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";
import initYoutube from "./utils";
import Anchor from "../../../Anchor";

const {
  VIDEO: {
    MIN_HEIGHT: { DESKTOP, MOBILE },
  },
} = MASTER_TEMPLATE_COMPONENT_CONFIGS;

@inject("BrandStore", "CommonStore")
@observer
class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
    this.setUpVideoAndInit = this.setUpVideoAndInit.bind(this);
    this.getCurrentDimensions = this.getCurrentDimensions.bind(this);
    this.onFrameLoad = this.onFrameLoad.bind(this);
    this.triggerGa = this.triggerGa.bind(this);
    this.initVideo = this.initVideo.bind(this);
  }
  componentDidMount() {
    console.log("ComponentPool->VideoComponent", this.props);
  }
  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error VideoComponent ${error} ${errorInfo} ${this}`,
    );
  }

  onFrameLoad() {
    this.setState({ loaded: true });
  }

  get src() {
    const { data } = this.props;
    return (data && data.video && data.video.url) || "";
  }
  get videoId() {
    const idFromParams = queryParamsToObject(this.src).v;
    const idFromPath = this.src && this.src.split(".be/")[1];
    return idFromParams || idFromPath;
  }
  get currentTitle() {
    const { data } = this.props;
    return (data && data.title && data.title.text) || "";
  }
  get currentTitleStyle() {
    const { data } = this.props;
    return (
      (data && data.title && data.title.color && { color: data.title.color }) ||
      {}
    );
  }
  get isLoaded() {
    const { loaded } = this.state;
    return loaded ? "is-loaded" : "";
  }
  get loaderBackground() {
    const { isDesktop } = this.props.CommonStore;
    const { loaded } = this.state;
    return loaded
      ? {}
      : {
          backgroundColor: getBackgroundColorCode(),
          minHeight: (isDesktop && DESKTOP) || MOBILE,
        };
  }
  get getTypeObj() {
    const { data } = this.props;

    const storyType = data && data.storyType;
    const pcmData = storyType && storyType.pcmData;
    const externalURL = storyType && storyType.externalURL;
    const { id, url } = deduceValuesFromPipedData(pcmData);
    const retUrl = url || externalURL;
    return {
      styleId: id,
      url: retUrl,
    };
  }
  get target() {
    const { url } = this.getTypeObj;
    const domain = `${window.location.hostname.split(".")[1]}.${
      window.location.hostname.split(".")[2]
    }`;
    return url && url.indexOf(domain) === -1 ? "_blank" : "_self";
  }
  setUpVideoAndInit(width, height) {
    initYoutube(() => {
      this.initVideo(width, height);
    });
  }
  getCurrentDimensions(ref) {
    const width = (ref && ref.offsetWidth) || 0;
    const height = Math.floor((width * 9) / 16);
    if (width && height) {
      this.setUpVideoAndInit(width, height);
    }
  }
  triggerGa(event) {
    if (event && event.data === window.YT.PlayerState.UNSTARTED) {
      const {
        sectionIndex,
        componentIndex,
        data: { identity },
      } = this.props;
      if (this.props.BrandStore.isBT) {
        Analytics.trackEvent({
          category: this.props.BrandStore.isBT,
          action: Event.action.getMasterTemplateActions([
            sectionIndex,
            Event.action.MASTER_TEMPLATE_PAGES.BT_VIDEO,
          ]),
          label: Event.label.getMasterTemplateLabels([
            componentIndex,
            identity,
          ]),
        });
        return null;
      }
      Analytics.trackEvent({
        category: window.pageType,
        action: Event.action.getMasterTemplateActions([
          sectionIndex,
          Event.action.MASTER_TEMPLATE_PAGES.VIDEO,
        ]),
        label: Event.label.getMasterTemplateLabels([this.src, identity]),
      });
    }
    return null;
  }
  initVideo(width, height) {
    const { autoPlay, allowFullScreen, noLogo } = this.props;
    const player =
      window.YT &&
      window.YT.Player &&
      new window.YT.Player("nw-youtube-videoplayer", {
        height,
        width,
        playerVars: {
          autoplay: autoPlay ? 1 : 0,
          controls: allowFullScreen ? 1 : 0,
          rel: 0,
          modestbranding: noLogo,
        },
        videoId: this.videoId,
        host: "https://www.youtube.com",
        events: {
          onReady: this.onFrameLoad,
          onStateChange: this.triggerGa,
        },
      });
    if (!player) {
      // console.log("Youtube Player Reinitialised");
      this.setUpVideoAndInit(width, height);
    }
  }
  render() {
    const { showTitle, modalView, className } = this.props;
    const { loaded } = this.state;
    return (
      <div
        className={`nw-videocomponent ${this.isLoaded} ${
          modalView ? "is-modalview" : ""
        } ${className}`}
        style={this.loaderBackground}
        ref={this.getCurrentDimensions}
      >
        <div
          className={`nw-videocomponent-iframe ${this.isLoaded} ${className}`}
        >
          <div id="nw-youtube-videoplayer" />
        </div>
        {loaded && showTitle && this.currentTitle && (
          <Anchor.component
            to={this.getTypeObj.url}
            href={this.getTypeObj.url}
            target={this.target}
            className={`nw-videocomponent-title ${className}`}
            style={this.currentTitleStyle}
          >
            {this.currentTitle}
            <i
              className={`icomoon-sephora_triangle nw-videocomponenttriangle ${className}`}
            />
          </Anchor.component>
        )}
        {(!loaded && modalView && <Loader />) || null}
      </div>
    );
  }
}
VideoComponent.wrappedComponent.defaultProps = {
  autoPlay: false,
  allowFullScreen: true,
  showTitle: true,
  modalView: false,
  noLogo: true,
};
VideoComponent.wrappedComponent.propTypes = {
  componentIndex: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  data: PropTypes.shape({}).isRequired,
  autoPlay: PropTypes.bool,
  allowFullScreen: PropTypes.bool,
  showTitle: PropTypes.bool,
  modalView: PropTypes.bool,
  noLogo: PropTypes.bool,
  BrandStore: PropTypes.shape({
    isBT: PropTypes.string.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("BrandStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: VideoComponent.wrappedComponent,
  component: VideoComponent,
};

export default ProjectComponent;
