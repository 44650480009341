import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { LazyImg, Modal, Utils } from "arv-reactcomponents";
import LazyLoad from 'react-lazyload';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  MASTER_TEMPLATE_COMPONENT_CONFIGS,
  EXCLUDE_STORY_BACKGROUND_COLOR,
  Endpoints,
  MASTERTEMPLATE,
} from "npmlinks-constants";
import {
  getBackgroundColorCode,
  deduceValuesFromPipedData,
  sanitiseUrl,
  isIframe,
  makeUrlAbsolute,
  imageFileName,
} from "../../../../utils";
import VideoComponent from "../VideoComponent";
import ProductBtns from "../../../../component/ProductList/ProductBtns";
import Analytics from "../../../../analytics";
import Event from "../../../../analytics/eventFactory";
import Anchor from "../../../Anchor";
import FavAndShare from "../FavAndShare";

@inject("BrandStore", "CommonStore")
@observer
class StoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      loaded: false,
      imgError: false,
    };

    this.promotions = [];
    this.isAllPromotions = false;
    this.brandText = "";
    this.productTitle = "";
    this.imageClick = this.imageClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.triggerGa = this.triggerGa.bind(this);
    this.onMainImgLoad = this.onMainImgLoad.bind(this);
    this.onMainImgError = this.onMainImgError.bind(this);
  }
  componentDidMount() {
    const { data } = this.props;
    const src = data && data.images && data.images[0] && data.images[0].path;
    if (!src) {
      this.props.hideComponent();
    }
    console.log("ComponentPool->StoryComponent", this.props);
  }

  componentDidCatch(error, errorInfo) {
    console.error(
      `ComponentPool->error StoryComponent ${error} ${errorInfo} ${this}`,
    );
  }
  get showBorder() {
    const { brandJourneyId } = this.props.CommonStore;
    return MASTER_TEMPLATE_COMPONENT_CONFIGS.STORY.SHOW_BORDER.includes(
      brandJourneyId,
    );
  }
  get storyTypeClass() {
    const { data } = this.props;
    const { type, storyType } = data || {};
    const typeClass =
      (type && type.toLowerCase() && `is-${type && type.toLowerCase()}`) || "";
    const storyTypetypeClass =
      (storyType &&
        storyType.type &&
        storyType.type.toLowerCase() &&
        `is-${storyType.type.toLowerCase()}`) ||
      "";
    return `${typeClass} ${storyTypetypeClass}`;
  }

  get currentSrc() {
    const { data } = this.props;
    const src = data && data.images && data.images[0] && data.images[0].path;
    return `${this.props.BrandStore.urlPrefix}${src}` || "";
  }
  get title() {
    const { data } = this.props;
    return (data && data.title && data.title.text) || "";
  }
  get titleStyle() {
    const { data } = this.props;
    const color = data && data.title && data.title.color;
    return color ? { color } : {};
  }
  get subtitle() {
    const { data } = this.props;
    return (data && data.subtitle && data.subtitle.text) || "";
  }
  get subtitleStyle() {
    const { data } = this.props;
    const color = data && data.subtitle && data.subtitle.color;
    return color ? { color } : {};
  }
  get cta() {
    const { data } = this.props;
    const { callToAction } = data;
    if (callToAction && callToAction.hasOwnProperty("text")) {
      return callToAction.text;
    }
    return callToAction || "";
  }
  get ctaStyle() {
    const { data } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const color =
      (data && data.callToAction && data.callToAction.color) ||
      (data && data.title && data.title.color);
    const borderColor = color && this.showBorder && isDesktop ? color : "";
    if (color && borderColor) {
      return { color, borderColor };
    } else if (color) {
      return { color };
    }
    return {};
  }
  get contentStyle() {
    const { data, BrandStore } = this.props;
    const { templateType } = BrandStore;
    // @TODO: Remove after response is corrected
    // to avoid mobile defaulf color code
    const backgroundColor =
      (!EXCLUDE_STORY_BACKGROUND_COLOR.includes(templateType) &&
        data &&
        data.backgroundColor) ||
      null;
    return backgroundColor ? { backgroundColor } : {};
  }
  get getTypeObj() {
    const { data } = this.props;
    const storyType = data && data.storyType;
    const pcmData = storyType && storyType.pcmData;
    const externalURL = storyType && storyType.externalURL;
    const { id, url } = deduceValuesFromPipedData(pcmData);
    const retUrl = url || sanitiseUrl(externalURL);
    return {
      styleId: id,
      url: retUrl || "",
    };
  }

  get imageStyle() {
    const { data } = this.props;
    const images = data && data.images;
    const [{ width = 0, height = 0 }] = images && images[0] ? images : [{}];
    const { loaded } = this.state;
    if (this.state.imgError) {
      const {
        defaultWidth,
        defaultHeight,
      } = MASTERTEMPLATE.DEFAULT_IMAGE_DIMENTIONS;
      return {
        paddingBottom: `${(defaultHeight / defaultWidth) * 100 - 0.5}%`,
      };
    }
    if (width && height) {
      return {
        paddingBottom: `${(height / width) * 100 - 0.5}%`,
        backgroundColor: !loaded && getBackgroundColorCode(),
      };
    }

    return { paddingBottom: 0 };
  }
  get showVideo() {
    const { data } = this.props;
    return data && data.video && data.video.url ? "" : "nwc-hide";
  }
  get showContent() {
    const { data } = this.props;
    const { share, favorite } = data;
    return this.title ||
      this.subtitle ||
      this.cta ||
      this.authorImage ||
      this.authorName ||
      share ||
      favorite
      ? ""
      : "nwc-hide";
  }
  get noContent() {
    return !(this.title || this.subtitle || this.cta);
  }

  get target() {
    const { isDesktop } = this.props.CommonStore;
    const { url } = this.getTypeObj;
    const domain = `${window.location.hostname.split(".")[1]}.${
      window.location.hostname.split(".")[2]
    }`;
    return isDesktop &&
      url &&
      url.indexOf(domain) === -1 &&
      /^(?:(https?:\/\/|\/\/))?(\d|\w){0,}-?\w+\.?\w+.\w{3}(?:(\/.*))?$/.test(
        url,
      )
      ? "_blank"
      : "_self";
  }
  get authorImage() {
    const { data } = this.props;
    const src =
      data &&
      data.bloggerImage &&
      data.bloggerImage[0] &&
      data.bloggerImage[0].path;
    return (src && `${this.props.BrandStore.urlPrefix}${src}`) || "";
  }

  get authorName() {
    const { data } = this.props;
    return (data && data.bloggerName && data.bloggerName.text) || "";
  }
  get storyDate() {
    const { data } = this.props;
    return (data && data.date && data.date.text) || "";
  }
  onMainImgLoad() {
    this.setState({ loaded: true });
  }
  onMainImgError() {
    this.setState({ imgError: true });
    this.props.onError();
  }
  imageClick(event) {
    const { data } = this.props;
    const videoUrl = (data && data.video && data.video.url) || "";

    this.triggerGa();
    if (videoUrl) {
      event.preventDefault();
      this.setState({ isModalOpen: true });
    }
  }
  closeModal() {
    this.setState({ isModalOpen: false });
  }
  triggerGa() {
    const {
      eventAction,
      componentIndex,
      sectionIndex,
      parentData,
      data: { identity, type },
    } = this.props;
    if (this.props.BrandStore.isBT) {
      Analytics.trackEvent({
        category: this.props.BrandStore.isBT,
        action: Event.action.getMasterTemplateActions([
          sectionIndex,
          (type === "blogStory" &&
            Event.action.MASTER_TEMPLATE_PAGES.BLOG_STORY) ||
            Event.action.MASTER_TEMPLATE_PAGES.STORY,
        ]),
        label: Event.label.getMasterTemplateLabels([componentIndex, identity]),
      });
      return null;
    }
    const sectionName =
      (parentData &&
        parentData.sectionTitle &&
        parentData.sectionTitle.split(" ").join("_")) ||
      "empty";
    const action =
      eventAction ||
      Event.action.getMasterTemplateActions([
        sectionIndex,
        Event.action.MASTER_TEMPLATE_PAGES.STORY,
      ]);
    const label = Event.label.getMasterTemplateLabels([
      sectionName,
      componentIndex,
      this.getTypeObj.url,
      identity,
    ]);
    Analytics.trackEvent({
      category: window.pageType,
      action,
      label,
    });
    return null;
  }
  render() {
    const { index, className, data, sectionIndex, componentIndex } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const { isModalOpen, imgError } = this.state;
    const { share = false, favorite = false, type: dataType } = data;
    const {pdpClassName = ""} = data;
    const storyType = data && data.storyType;
    const { identity } = data;
    const type = storyType && storyType.type;
    return (
      <div
        className={`nw-storycomponent ${className} ${(this.noContent &&
          "no-content") ||
          ""}`}
      >
        <Anchor.component
          className={`nw-storycomponent-anchor ${
            this.storyTypeClass
          } ${className}`}
          to={this.getTypeObj.url}
          href={this.getTypeObj.url}
          onClick={this.imageClick}
          target={this.target}
        >
          <i
            className={`icomoon-play2 nw-storycomponent-videoicon ${
              this.showVideo
            } ${className}`}
          />
          <div
            className={`nw-storycomponent-imagewrapper ${className}`}
            style={this.imageStyle}
          >
            <LazyLoad>
            <LazyImg
              index={index}
              src={(imgError && Endpoints.noImg) || this.currentSrc}
              alt={imageFileName(this.currentSrc)}
              className={`nw-storycomponent-image ${className} ${pdpClassName}`}
              rootMargin={`1500px`}
              onLoad={this.onMainImgLoad}
              onError={this.onMainImgError}
              onWinLoad={!isDesktop || isIframe}
            />
            </LazyLoad>
          </div>
          {((type === "product" || type === "style") && (
            <ProductBtns.component
              index={index}
              promotions={this.promotions}
              isAllPromotions={this.isAllPromotions}
              mainImage={this.currentSrc}
              brandText={this.brandText}
              productTitle={this.productTitle}
              styleId={this.getTypeObj.styleId}
              productUrl={this.getTypeObj.url}
            />
          )) ||
            null}
        </Anchor.component>
        <div
          className={`nw-storycomponent-content ${className} ${
            this.showContent
          }`}
          style={this.contentStyle}
        >
          {this.title && (
            <h3
              className={`nw-storycomponent-title ${className} ${
                this.storyTypeClass
              }`}
              style={this.titleStyle}
            >
              {this.title}
            </h3>
          )}
          {this.subtitle && (
            <div
              className={`nw-storycomponent-subtitle ${className} ${
                this.storyTypeClass
              }`}
              style={this.subtitleStyle}
            >
              {this.subtitle}
            </div>
          )}
          {this.storyDate && (
            <div className={`nw-storycomponent-date ${className}`}>
              {this.storyDate}
            </div>
          )}
          <div className={`nw-storycomponent-blog ${className}`}>
            <div className={`nw-storycomponent-authordetails ${className}`}>
              {this.authorImage && (
                <div
                  className={`nw-storycomponent-authorimagewrapper ${className}`}
                >
                  <LazyLoad>
                  <LazyImg
                    index={index}
                    src={(imgError && Endpoints.noImg) || this.authorImage}
                    alt={imageFileName(this.authorImage)}
                    className={`nw-storycomponent-authorimage ${className}`}
                    rootMargin={`1500px`}
                    onWinLoad={!isDesktop || isIframe}
                  />
                  </LazyLoad>
                </div>
              )}
              {this.authorName && (
                <h3 className={`nw-storycomponent-authorname ${className}`}>
                  {this.authorName}
                </h3>
              )}
            </div>
            <div>
              <FavAndShare.component
                view="relative"
                title={this.title}
                description={this.subtitle}
                url={makeUrlAbsolute(this.getTypeObj.url)}
                image={(imgError && Endpoints.noImg) || this.currentSrc}
                shareable={share}
                favoritable={favorite}
                id={makeUrlAbsolute(this.getTypeObj.url)}
                type="blogs"
                category={dataType}
                GAObj={{
                  componentIndex,
                  sectionIndex,
                  identity,
                  type: Event.action.MASTER_TEMPLATE_PAGES.BLOG_STORY_SHARE,
                }}
              />
            </div>
          </div>
          {this.cta && (
            <Anchor.component
              className={`nwc-btn nw-storycomponent-cta ${className}`}
              to={this.getTypeObj.url}
              href={this.getTypeObj.url}
              style={this.ctaStyle}
              target={this.target}
              onClick={this.triggerGa}
            >
              {this.cta}
              <i
                className={`icomoon-sephora_triangle nw-story-cta-triangle ${className}`}
              />
            </Anchor.component>
          )}
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={this.closeModal}
          className={`nw-storycomponent-videopopup ${className}`}
        >
          <VideoComponent.component
            data={data}
            autoPlay
            modalView
            allowFullScreen={false}
            showTitle={false}
            sectionIndex={sectionIndex}
            componentIndex={componentIndex}
          />
        </Modal>
      </div>
    );
  }
}
StoryComponent.wrappedComponent.defaultProps = {
  index: 0,
  className: "",
  eventAction: "",
  componentIndex: 0,
  sectionIndex: 0,
  parentData: {},
  hideComponent: Utils.noop,
  onError: Utils.noop,
};
StoryComponent.wrappedComponent.propTypes = {
  onError: PropTypes.func,
  hideComponent: PropTypes.func,
  parentData: PropTypes.shape({}),
  eventAction: PropTypes.string,
  componentIndex: PropTypes.number,
  sectionIndex: PropTypes.number,
  data: PropTypes.shape({}).isRequired,
  index: PropTypes.number,
  className: PropTypes.string,
  BrandStore: PropTypes.shape({
    isBT: PropTypes.string.isRequired,
    urlPrefix: PropTypes.string.isRequired,
    templateType: PropTypes.string.isRequired,
    brandId: PropTypes.string.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    brandJourneyId: PropTypes.string.isRequired,
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("BrandStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: StoryComponent.wrappedComponent,
  component: StoryComponent,
};

export default ProjectComponent;
